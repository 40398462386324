var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{attrs:{"title":_vm.$t('ad-spaces'),"headers":_vm.headers,"actions":_vm.actions,"provider":_vm.$adSpaces,"fetch-function":_vm.getAdSpaces,"translate":""},on:{"view":_vm.onInitView,"update":_vm.onInitUpdate,"delete":_vm.onInitDelete,"status":_vm.onChangeStatus,"click:add":_vm.onInitCreate},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.$adSpaces.perPage * (_vm.$adSpaces.currentPage - 1) + index + 1)+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$m(item.toEndDate).diff(item.fromStartDate, 'days'))+" "+_vm._s(_vm.$t('days'))+" ")]}},{key:"item.fromStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$m(item.fromStartDate).format('YYYY-MM-DD'))+" ")]}},{key:"item.toEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$m(item.toEndDate).format('YYYY-MM-DD'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-capitalize tw-text-sm tw-font-semibold",class:item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)}),_c('base-view-table',{attrs:{"item":_vm.apSpace.ref,"headers":_vm.viewHeaders,"title":_vm.$t('view-ad-space-information'),"tag":"v-dialog"},scopedSlots:_vm._u([{key:"creator",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$get(item, 'creator.name')))]),_c('div',{staticClass:"tw-opacity-80"},[_vm._v(" "+_vm._s(_vm.$get(item, 'creator.email'))+" ")])]}},{key:"totalDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$m(item.toEndDate).diff(item.fromStartDate, 'days'))+" "+_vm._s(_vm.$t('days'))+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-capitalize tw-text-sm tw-font-semibold",class:item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'},[_vm._v(" "+_vm._s(item.status)+" ")])]}}]),model:{value:(_vm.apSpace.isView),callback:function ($$v) {_vm.$set(_vm.apSpace, "isView", $$v)},expression:"apSpace.isView"}}),_c('FormAdSpace',{attrs:{"adSpace":_vm.apSpace.ref},model:{value:(_vm.apSpace.isCreateUpdate),callback:function ($$v) {_vm.$set(_vm.apSpace, "isCreateUpdate", $$v)},expression:"apSpace.isCreateUpdate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }