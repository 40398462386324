<template>
	<div>
		<base-data-table
			:title="$t('ad-spaces')"
			:headers="headers"
			:actions="actions"
			:provider="$adSpaces"
			:fetch-function="getAdSpaces"
			translate
			@view="onInitView"
			@update="onInitUpdate"
			@delete="onInitDelete"
			@status="onChangeStatus"
			@click:add="onInitCreate"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{ $adSpaces.perPage * ($adSpaces.currentPage - 1) + index + 1 }}
			</template>

			<template v-slot:[`item.duration`]="{ item }">
				{{ $m(item.toEndDate).diff(item.fromStartDate, 'days') }}
				{{ $t('days') }}
			</template>

			<template v-slot:[`item.fromStartDate`]="{ item }">
				{{ $m(item.fromStartDate).format('YYYY-MM-DD') }}
			</template>

			<template v-slot:[`item.toEndDate`]="{ item }">
				{{ $m(item.toEndDate).format('YYYY-MM-DD') }}
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<div
					:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status }}
				</div>
			</template>
		</base-data-table>

		<base-view-table
			v-model="apSpace.isView"
			:item="apSpace.ref"
			:headers="viewHeaders"
			:title="$t('view-ad-space-information')"
			tag="v-dialog"
		>
			<!-- CREATOR -->
			<template #creator="{ item }">
				<div>{{ $get(item, 'creator.name') }}</div>
				<div class="tw-opacity-80">
					{{ $get(item, 'creator.email') }}
				</div>
			</template>

			<!-- DURATION -->
			<template #totalDays="{ item }">
				{{ $m(item.toEndDate).diff(item.fromStartDate, 'days') }}
				{{ $t('days') }}
			</template>

			<!-- STATUS -->
			<template #status="{ item }">
				<div
					:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status }}
				</div>
			</template>
		</base-view-table>

		<FormAdSpace v-model="apSpace.isCreateUpdate" :adSpace="apSpace.ref" />
		<!-- @adSpace="getAdSpaces()" -->
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import FormAdSpace from '@/components/forms/FormAdSpace.vue'

const newAdSpace = (v = {}) => ({
	ref: null,
	isView: false,
	isDelete: false,
	isStatus: false,
	isCreateUpdate: false,
	...v,
})

export default {
	name: 'AdSpaces',
	components: { FormAdSpace },
	data() {
		return {
			apSpace: newAdSpace(),
			headers: [
				{ text: 'headers.ns', value: 'serial' },
				{
					text: this.$t('category'),
					value: 'category.name',
					isGrow: 1,
				},
				{
					text: 'headers.costPerDay',
					value: 'chargePerDay',
					computed: (v) => `SAR ${v.chargePerDay}`,
				},
				{ text: 'headers.startDate', value: 'fromStartDate' },
				{ text: 'headers.endDate', value: 'toEndDate' },

				{
					text: 'headers.duration',
					value: 'duration',
				},
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.actions', value: 'actions' },
			],
			viewHeaders: [
				{
					text: 'creator',
					value: 'creator',
				},
				{
					text: 'category',
					value: 'category.name',
				},
				{
					text: 'headers.costPerDay',
					value: (v) => `SAR ${v.chargePerDay}`,
				},
				{
					text: 'headers.duration',
					value: 'totalDays',
				},
				{ text: 'headers.startDate', value: 'fromStartDate' },
				{ text: 'headers.endDate', value: 'toEndDate' },
			],
			actions: [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye',
				},
				{
					text: this.$t('edit-information'),
					event: 'update',
					icon: 'mdi-pencil',
				},
				{
					divider: true,
				},
				{
					icon: 'mdi-checkbox-intermediate',
					event: 'status',
					text: (v) => {
						return v.status === 'active'
							? this.$t('actions.deactivate')
							: this.$t('actions.activate')
					},
				},
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
					color: 'error',
				},
			],
		}
	},
	computed: {
		...mapGetters('adSpaces', ['$adSpaces']),
	},
	methods: {
		...mapActions('adSpaces', [
			'getAdSpaces',
			'deleteAdSpace',
			'updateAdSpace',
		]),
		onResetAdSpace() {
			this.apSpace = newAdSpace({
				ref: this.apSpace.ref,
			})
		},
		onInitCreate() {
			this.apSpace = newAdSpace({
				isCreateUpdate: true,
			})
		},
		onInitView(event) {
			this.apSpace = newAdSpace({
				ref: event,
				isView: true,
			})
		},
		onInitUpdate(event) {
			this.apSpace = newAdSpace({
				ref: event,
				isCreateUpdate: true,
			})
		},
		onInitDelete(event) {
			this.$root.confirmation = {
				title: this.$t('delete-ad-space'),
				message: `Are you sure you want to delete this Ad Space?`,
				handler: async () => {
					await this.deleteAdSpace(event.id)
				},
			}
		},
		onChangeStatus(e) {
			const type =
				e.status === 'active'
					? this.$t('actions.deactivate')
					: this.$t('actions.activate')
			this.$root.confirmation = {
				title: type + ' ' + this.$t('ad-space'),
				message: this.$t('sure-to-update-ad-space', [type]),
				handler: async () => {
					await this.updateAdSpace({
						id: e.id,
						categoryId: e.category.id,
						chargePerDay: e.chargePerDay,
						toEndDate: e.toEndDate.substring(0, 10),
						fromStartDate: e.fromStartDate.substring(0, 10),
						status: e.status === 'active' ? 'deactive' : 'active',
					})
				},
			}
		},
	},
}
</script>
