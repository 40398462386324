<template>
	<v-dialog
		v-model="dialog"
		scrollable
		persistent
		max-width="768"
		class="tw-rounded-2xl tw-shadow-lg"
	>
		<v-form
			v-model="isValid"
			ref="formRef"
			lazy-validation
			@submit.prevent="onSubmitAdSpace"
		>
			<v-card class="tw-overflow-x-hidden tw-overflow-y-auto tw-p-4">
				<v-card-title
					class="tw-font-Poppins-text tw-font-semibold tw-text-22 tw-mb-4"
				>
					{{ isUpdate ? `Update Ad Space` : 'Create New Ad Space' }}
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col cols="7">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('classification') }}
							</div>
							<v-select
								v-model="mAdSpace.category"
								:items="categories"
								:placeholder="$t('classification')"
								:rules="[rules.required($t('classification'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:loading="isFetchingCategories"
								flat
								dense
								outlined
								return-object
								class="user-input"
								hide-details="auto"
								item-value="id"
								item-text="name"
							/>
						</v-col>

						<v-col cols="5">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('price-per-day') }}
							</div>
							<v-text-field
								v-model="mAdSpace.chargePerDay"
								:placeholder="$t('00')"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								flat
								dense
								outlined
								prefix="SAR"
								class="user-input"
								hide-details="auto"
							/>
						</v-col>

						<v-col cols="12" sm="6">
							<BaseDatePicker
								v-model="mAdSpace.fromStartDate"
								:max="mAdSpace.toEndDate"
								:label="$t('available-from')"
								:rules="[rules.required($t('startDate'))]"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:placeholder="$t('select-start-date')"
								input-class="user-input"
							/>
						</v-col>

						<v-col cols="12" sm="6">
							<BaseDatePicker
								v-model="mAdSpace.toEndDate"
								:min="mAdSpace.fromStartDate"
								:label="$t('available-to')"
								:rules="[rules.required($t('endDate'))]"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:placeholder="$t('select-end-date')"
								input-class="user-input"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions class="tw-mt-4 tw-space-x-6 tw-px-4 tw-pb-4">
					<v-btn
						text
						outlined
						height="58"
						color="primary"
						class="tw-flex-1 tw-rounded-lg"
						@click="dialog = false"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>
							{{ $t('actions.cancel') }}
						</span>
					</v-btn>
					<v-btn
						:loading="isLoading"
						type="submit"
						height="58"
						class="tw-flex-1 tw-font-medium tw-rounded-lg"
						depressed
						color="primary"
						><span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>
							{{
								isUpdate ? $t('actions.update') : $t('actions.submit')
							}}
						</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import Api from '@/api'

import { mapActions } from 'vuex'
import { omitEmpties } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'

export default {
	name: 'FormAdSpace',
	mixins: [
		createFormMixin({
			rules: ['required'],
		}),
	],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		adSpace: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			mAdSpace: {},
			categories: [],

			isValid: false,
			isLoading: false,
			isFetchingCategories: false,
		}
	},
	watch: {
		value(v) {
			if (!v) return

			this.onFetchCategories()

			this.$refs?.formRef?.reset()
			this.mAdSpace = this.newAdSpace(this.adSpace)
		},
		adSpace: {
			deep: true,
			immediate: true,
			handler(v) {
				this.mAdSpace = this.newAdSpace(v)
			},
		},
	},
	computed: {
		isUpdate() {
			return !!this.mAdSpace.id
		},
		dialog: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	methods: {
		...mapActions('adSpaces', ['updateAdSpace', 'createAdSpace']),
		async fetchCategories(payload = {}) {
			const [err, res] = await Api.categories.get({
				can_paginate: 0,
				type: 'ad-space',
				...payload,
			})
			if (err) return
			this.categories = res.data
		},
		async onFetchCategories() {
			this.isFetchingCategories = true
			await this.fetchCategories()
			this.isFetchingCategories = false
		},
		newAdSpace(v) {
			return {
				id: v?.id,
				category: v?.category,
				status: v?.status ?? 'active',
				chargePerDay: v?.chargePerDay,
				fromStartDate: v?.fromStartDate?.substring(0, 10),
				toEndDate: v?.toEndDate?.substring(0, 10),
			}
		},
		async onSubmitAdSpace() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			const type = this.isUpdate ? 'update' : 'create'
			this.$root.confirmation = {
				title: this.$t('ad-space'),
				message: this.$t('sure-change-ad-space', [type]),
				handler: async () => {
					const action = this.isUpdate
						? this.updateAdSpace
						: this.createAdSpace

					this.isLoading = true
					const [err, res] = await action(
						omitEmpties({
							...this.mAdSpace,
							category: null,
							categoryId: this.mAdSpace.category?.id,
						})
					)
					this.isLoading = false

					if (err) {
						this.$utils.scrollIntoError(this)
						return this.$toast.error(
							err.message || this.$t('error-changing-ad-space', [type])
						)
					}

					this.$refs.formRef.reset()
					this.dialog = false
					this.$emit('adSpace', res.adSpace)
				},
			}
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	opacity: 0.35;
	color: black !important;
	font-size: 16px !important;
}
</style>
